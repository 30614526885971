export const isBot = (request) => {
  const bots =
    'googlebot|Yahoo! Slurp|bingbot|yandex|baiduspider|facebookexternalhit|twitterbot|rogerbot|linkedinbot|embedly|quora link preview|showyoubot|outbrain|pinterest/0.|developers.google.com/+/web/snippet|slackbot|vkShare|W3C_Validator|redditbot|Applebot|WhatsApp|flipboard|tumblr|bitlybot|SkypeUriPreview|nuzzel|Discordbot|Google Page Speed|Qwantify|pinterestbot|Bitrix link preview|XING-contenttabreceiver|Chrome-Lighthouse|TelegramBot|SeznamBot|AhrefsSiteAudit|AhrefsBot';
  const botsArr = bots?.split('|');

  let isBot = false;
  botsArr.forEach((bot) => {
    if (request?.headers['user-agent']?.includes(bot)) {
      isBot = true;
      return;
    }
  });

  return isBot;
};
