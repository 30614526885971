// Log the pageview with their URL
export const pageview = (url) => {
  window.gtag('config', process.env.NEXT_PUBLIC_GA_GA4, {
    page_path: url,
    page_title: document.title,
  });
};

export const matomoViewer = (url) => {
  if (window && window._paq) {
    _paq.push(['setCustomUrl', url]);
    _paq.push(['setDocumentTitle', document.title]);
    _paq.push(['trackPageView']);
  }
};

// Log specific events happening.
export const event = ({ action, params }) => {
  window.gtag('event', action, params);
};

export const matomoEvent = ({ category, action, name }) => {
  if (window && window._paq) {
    _paq.push(['trackEvent', category, action, name]);
  }
};

export const trackingShareVenueClick = ({
  articleName = '',
  venueName = '',
}) => {
  matomoEvent({
    category: articleName + ' - ' + 'In-Article Listing Widget',
    action: `[In-Article Listing Widget] ${TRACKING_EVENTS.SHARE_BUTTON_CLICKS}`,
    name: articleName + ' - ' + venueName,
  });

  event({
    action: `[In-Article Listing Widget] ${TRACKING_EVENTS.SHARE_BUTTON_CLICKS}`,
    params: {
      category: articleName + ' - ' + 'In-Article Listing Widget',
      label: articleName + ' - ' + venueName,
    },
  });
};

export const trackingAddFavouriteVenueClick = ({
  articleName = '',
  venueName = '',
}) => {
  matomoEvent({
    category: articleName + ' - ' + 'In-Article Listing Widget',
    action: `[In-Article Listing Widget] ${TRACKING_EVENTS.FAVOURITE_BUTTON_CLICKS}`,
    name: articleName + ' - ' + venueName,
  });

  event({
    action: `[In-Article Listing Widget] ${TRACKING_EVENTS.FAVOURITE_BUTTON_CLICKS}`,
    params: {
      category: articleName + ' - ' + 'In-Article Listing Widget',
      label: articleName + ' - ' + venueName,
    },
  });
};

export const trackingViewImagesVenueScroll = ({
  articleName = '',
  venueName = '',
}) => {
  matomoEvent({
    category: articleName + ' - ' + 'In-Article Listing Widget',
    action: `[In-Article Listing Widget] ${TRACKING_EVENTS.SCROLL_THROUGH_IMAGES}`,
    name: articleName + ' - ' + venueName,
  });

  event({
    action: `[In-Article Listing Widget] ${TRACKING_EVENTS.SCROLL_THROUGH_IMAGES}`,
    params: {
      category: articleName + ' - ' + 'In-Article Listing Widget',
      label: articleName + ' - ' + venueName,
    },
  });
};

export const trackingViewScroll = ({
  articleName = '',
  trackingType = '',
  venueName = '',
}) => {
  matomoEvent({
    category: articleName + ' - ' + 'In-Article Listing Widget',
    action: `[${trackingType}] ${TRACKING_EVENTS.SCROLL_INTO_VIEW}`,
    name: `${articleName}${venueName ? ` - ${venueName}` : venueName}`,
  });

  event({
    action: `[${trackingType}] ${TRACKING_EVENTS.SCROLL_INTO_VIEW}`,
    params: {
      category: articleName + ' - ' + 'In-Article Listing Widget',
      name: `${articleName}${venueName ? ` - ${venueName}` : venueName}`,
    },
  });

  console.log('trackingViewScroll');
};

export const trackingExternalLinkClick = ({
  articleName = '',
  externalLink = '',
}) => {
  matomoEvent({
    category: 'External Link Tracker',
    action: `[External Link Tracker] ${TRACKING_EVENTS.LINK_ARE_CLICKED}`,
    name: `${articleName}${externalLink ? ` - ${externalLink}` : externalLink}`,
  });

  event({
    action: `[External Link Tracker] ${TRACKING_EVENTS.LINK_ARE_CLICKED}`,
    params: {
      category: 'External Link Tracker',
      name: `${articleName}${
        externalLink ? ` - ${externalLink}` : externalLink
      }`,
    },
  });
};

export const trackingWidgetClick = ({ articleName, venueName }) => {
  matomoEvent({
    category: articleName + ' - ' + 'In-Article Listing Widget',
    action: `[In-Article Listing Widget] ${TRACKING_EVENTS.WIDGET_CLICKS}`,
    name: `${articleName}${venueName ? ` - ${venueName}` : venueName}`,
  });

  event({
    action: `[In-Article Listing Widget] ${TRACKING_EVENTS.WIDGET_CLICKS}`,
    params: {
      category: articleName + ' - ' + 'In-Article Listing Widget',
      name: `${articleName}${venueName ? ` - ${venueName}` : venueName}`,
    },
  });
};

export const trackingBrandedClick = (articleName) => {
  matomoEvent({
    category: articleName + ' - ' + 'In-Article Branded Placement',
    action: `[In-Article Branded Placement] ${TRACKING_EVENTS.LINK_ARE_CLICKED}`,
    name: articleName,
  });

  event({
    action: `[In-Article Branded Placement] ${TRACKING_EVENTS.LINK_ARE_CLICKED}`,
    params: {
      category: articleName + ' - ' + 'In-Article Branded Placement',
      name: articleName,
    },
  });
};

export default {
  pageview,
  event,
  matomoViewer,
  matomoEvent,
  trackingShareVenueClick,
  trackingAddFavouriteVenueClick,
  trackingViewImagesVenueScroll,
  trackingViewScroll,
  trackingExternalLinkClick,
  trackingWidgetClick,
  trackingBrandedClick,
};

export const gaData = {
  article: {
    action: 'Publication | Share Article via Sticky Bar',
    category: 'Share Article via Sticky Bar',
  },
  bucket: {
    actionCreate: 'Publication | Create Bucket List',
    actionSuccess: 'Publication | Success',
    actionFailure: 'Publication | Failure',
    categoryAdd: 'Add To Bucket',
    categoryCreate: 'Create Bucket List',
  },
};

export const TRACKING_EVENTS = {
  SCROLL_INTO_VIEW: 'Scroll Into View',
  FAVOURITE_BUTTON_CLICKS: 'Favourite Button Clicks',
  SHARE_BUTTON_CLICKS: 'Share Button Clicks',
  SCROLL_THROUGH_IMAGES: 'Scroll Through Images',
  WIDGET_CLICKS: 'Widget Clicks',
  LINK_ARE_CLICKED: 'Links Are Clicked',
};
